function wakeupChatBot(
  avatarId,
  primaryColor,
  messageList,
  imgUrl,
  font,
  fontColor,
  fontSize,
  zIndex
) {
  let closeButton = document.createElement("div");
  closeButton.innerHTML = `<img onclick="return closeAvatarLabsWidget()" src="https://widget.avatarlabs.ai/closeIcon.png" alt="" style="height: 28px; width: 28px;">`;
  closeButton.id = "dynamic_close_icon";
  closeButton.style.display = "none";
  closeButton.style.position = "fixed";
  closeButton.style.top = "12px";
  closeButton.style.right = "6px";
  closeButton.style.zIndex = zIndex ? Number(zIndex) + 5 : "10";
  closeButton.style.cursor = "pointer";

  var avatarLabsChatWidget = document.createElement("iframe");
  avatarLabsChatWidget.src = `https://widget.avatarlabs.ai?avatar_id=${avatarId}`;
  // avatarLabsChatWidget.src = `http://localhost:5173/?avatar_id=${avatarId}`;
  avatarLabsChatWidget.style.zIndex = zIndex ? zIndex : "10";
  avatarLabsChatWidget.id = "avatar_lab_iframe";
  avatarLabsChatWidget.crossorigin = "anonymous";
  // avatarLabsChatWidget.style.display = "block";
  avatarLabsChatWidget.style.display = "none";
  avatarLabsChatWidget.style.width = "50vw";
  avatarLabsChatWidget.style.minWidth = "50vw";
  avatarLabsChatWidget.style.height = "100vh";
  avatarLabsChatWidget.style.position = "fixed";
  avatarLabsChatWidget.style.bottom = "0%";
  avatarLabsChatWidget.style.right = "0%";
  avatarLabsChatWidget.style.borderRadius = "0px";
  avatarLabsChatWidget.style.border = "0px solid black";

  function typingAnimation() {
    let stringObj = {
      strings: messageList,
      stringsElement: null,
      typeSpeed: 75,
      backSpeed: 75,
      backDelay: 5000,
      loop: !0,
    };
    new Typed(".typed", stringObj);

    const typedCur = document.getElementsByClassName("typed-cursor");
    typedCur[0].style.color = `#${fontColor}`;

    const typed = document.getElementsByClassName("typed");
    typed[0].style.fontSize = `${fontSize}px`;
  }

  document.body.appendChild(avatarLabsChatWidget);

  var popUpButton = document.createElement("div");

  var avatarContainer = document.createElement("div");

  avatarContainer.style.padding = "";

  var avatarImg = document.createElement("img");
  var animatedTextContainer = document.createElement("span");

  animatedTextContainer.style.color = `#${fontColor}`;
  animatedTextContainer.style.fontFamily = font;

  var animatedButtonWithAvatar = document.createElement("div");

  animatedTextContainer.className = "typed";

  animatedButtonWithAvatar.style.display = "flex";
  animatedButtonWithAvatar.style.fontFamily = "arial";

  avatarImg.src = imgUrl;
  avatarImg.style.height = "84px";
  avatarImg.style.width = "84px";
  avatarImg.style.borderRadius = "50%";
  avatarImg.style.marginLeft = "12px";
  avatarImg.style.marginRight = "-26px";

  avatarContainer.appendChild(avatarImg);
  animatedButtonWithAvatar.appendChild(avatarContainer);
  animatedButtonWithAvatar.appendChild(animatedTextContainer);

  popUpButton.style.position = "fixed";
  popUpButton.style.bottom = "3%";
  popUpButton.style.right = "3%";
  popUpButton.style.padding = "0px 18px 0px 14px";
  popUpButton.style.height = "70px";
  popUpButton.style.borderRadius = "35px";
  popUpButton.style.backgroundColor = primaryColor;
  popUpButton.style.cursor = "pointer";
  popUpButton.style.display = "flex";
  popUpButton.style.alignItems = "center";
  popUpButton.style.justifyContent = "center";
  popUpButton.style.zIndex = zIndex ? zIndex - 1 : "9";

  var popUpImg = document.createElement("img");
  popUpImg.src = "https://widget.avatarlabs.ai/chaticon.png";
  popUpImg.style.height = "42px";
  popUpImg.style.width = "42px";

  popUpButton.appendChild(animatedTextContainer);
  popUpButton.appendChild(avatarContainer);

  popUpButton.onclick = openAvatarLabsWidget;

  document.body.appendChild(popUpButton);
  document.body.appendChild(closeButton);

  setTimeout(() => {
    // openAuto();
    typingAnimation();
  }, 1000);

  function removeSpaceFn() {
    var iframe = document.getElementById("avatar_lab_iframe");
    var elmnt = iframe.contentWindow.document.getElementsByTagName("body");
    elmnt[0].style.margin = "0px";
  }
  removeSpaceFn();

  function customMediaQuery(x) {
    if (x.matches) {
      // If media query matches
      avatarLabsChatWidget.style.width = "50vw";
      avatarLabsChatWidget.style.minWidth = "50vw";
      avatarLabsChatWidget.style.height = "50vh";
    } else {
      avatarLabsChatWidget.style.width = "50vw";
      avatarLabsChatWidget.style.minWidth = "50vw";
      avatarLabsChatWidget.style.height = "100vh";
    }
  }

  // Create a MediaQueryList object
  var x = window.matchMedia("(max-width: 576px)");

  // Call listener function at run time
  customMediaQuery(x);

  // Attach listener function on state changes
  x.addEventListener("change", function () {
    customMediaQuery(x);
  });

  function customMediaQuery(x) {
    if (x.matches) {
      // If media query matches
      avatarLabsChatWidget.style.width = "100vw";
      avatarLabsChatWidget.style.minWidth = "50vw";
      avatarLabsChatWidget.style.height = "100vh";
    } else {
      avatarLabsChatWidget.style.width = "50vw";
      avatarLabsChatWidget.style.minWidth = "50vw";
      avatarLabsChatWidget.style.height = "100vh";
    }
  }

  function customMediaQueryFor769(x) {
    if (x.matches) {
    } else {
    }
  }
  // Create a MediaQueryList object
  var match769Query = window.matchMedia("(max-width: 769px)");

  // Call listener function at run time
  customMediaQueryFor769(match769Query);

  // Attach listener function on state changes
  x.addEventListener("change", function () {
    customMediaQueryFor769(match769Query);
  });
}

function openAvatarLabsWidget() {
  var avatarLabsChatWidget = document.getElementById("avatar_lab_iframe");
  avatarLabsChatWidget.style.display = "block";
  document.getElementById("dynamic_close_icon").style.display = "block";
}

function closeAvatarLabsWidget() {
  var avatarLabsChatWidget = document.getElementById("avatar_lab_iframe");
  avatarLabsChatWidget.style.display = "none";
  document.getElementById("dynamic_close_icon").style.display = "none";
}
